.formContainer {
  position: absolute;
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: center;
  z-index: 100002;
  width: 100%;
  max-width: 560px;
  left: 50%;
  bottom: 64px;
  transform: translate(-50%, 0);
}

.title {
  font-family: 'Space Grotesk', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
  position: absolute;
  top: 10px;
  left: 50%;
  z-index: 10001;
  transform: translate(-50%, 0);
  background: rgba(23, 26, 48, 0.3);
  border-radius: 24px;
  padding: 8px 40px;
}

/*mobile*/
@media (max-width: 719px) {
  .formContainer {
    width: 90%;
    bottom: 24px;
  }

  .title {
    width: 75%;
  }
}
