.root {
  cursor: pointer;
  padding: 8px 48px;
  height: 48px;
  border-radius: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 48px;
  letter-spacing: -0.02em;
  color: #3D0F99;
  background: #D4FF00;
}

.root:hover {
  background: #E7FF72;
}

.root:active {
  background: #AACD00;
}

