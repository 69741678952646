.powered {
  position: absolute;
  bottom: 32px;
  left: 32px;
  z-index: 10001;
  cursor: pointer;
  gap: 18px;
  height: 44px;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 4px 4px 4px 8px;
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
}

.text {
  font-size: 16px;
  line-height: 100%;
  font-weight: 700;
  color: #3D0F99;
  font-style: normal;
  font-family: 'Space Grotesk', sans-serif;
}

.powered_name {
  text-decoration: underline;
}

.text_desktop {
  display: block;
}

.text_mobile {
  display: none;
}

.textDarkOrWhite {
  color: #0F0F4C;
}

.poweredMobile {
    position: absolute;
    left: 200px;
    transform: translate(-50%, 0);
    bottom: 24px;
    box-sizing: border-box;
    width: auto;
}

.poweredDesktop {
    position: absolute;
    left: 32px;
    bottom: 32px;
    box-sizing: border-box;
}

@media (max-width: 867px) {
  .powered {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 20px;
    box-sizing: border-box;
    width: 90%;
  }

  .image {
    display: none;
  }

  .textDarkOrWhite {
    color: #FCFEFF;
  }

  .poweredTop {
    top: 0;
    bottom: 0;
    border-radius: 0;
    transform: translate(0, 0);
    left: 0;
    background: rgba(23, 26, 48, 0.3);
    width: 100%;
    justify-content: center;
    backdrop-filter: blur(8px);
    height: 32px;
    color: #FCFEFF;
  }

  .text_desktop {
    display: none;
  }

  .text_mobile {
    display: block;
  }
}
