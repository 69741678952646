.title {
  font-family: 'Space Grotesk', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 108%;
  text-align: center;
  letter-spacing: -0.04em;
  margin-bottom: 40px;
  color: #FFFFFF;
}

.controlBox {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.buttons {
  display: flex;
  gap: 8px;
}

.formTitle {
  text-align: center;
}

.formTitleText {
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 108%;
  text-align: center;
  letter-spacing: -0.04em;
  padding-bottom: 12px;
}

@media (max-width: 719px) {
  .buttons {
    margin-top: 16px;
  }
}
