.root {
  width: 56px;
  height: 56px;
  cursor: pointer;
  border-radius: 80px;
  border: 4px solid #191C3E;
}

.root:hover, .root:active {
  border-radius: 80px;
  border: 4px solid #7A3AFF;
}

.root:hover::after, .root:active:after {
  display: block;
  content: "";
  width: 64px;
  height: 64px;
  position: absolute;
  top: -4px;
  left: -4px;
  z-index: 10;
  border-radius: 80px;
  background-image: radial-gradient(ellipse, rgba(122, 58, 255, 0.2) 60%, rgba(122, 58, 255, 0.8) 100%);
}

.root:active:after {
  background-image: radial-gradient(ellipse, rgba(140, 85, 255, 0.2) 30%, rgba(140, 85, 255, 0.8) 100%);
}
