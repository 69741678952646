.root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100%;
  z-index: 1000;
  position: absolute;
  width: 100%;
  background-size: cover !important;
  background: url('../../../../../assets/loader-bg.svg') #4612B3 repeat;
}

.background {
  position: absolute;
  min-height: 100%;
  width: 100%;
  z-index: -1;
  background-size: cover !important;
  background: url('../../../../../assets/loader-bg.svg') #4612B3 repeat;
}

.noneBackground {
  background: transparent;
}

.rootBackground {
  opacity: 1;
  transition-duration: .5s;
}

.rootShow {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s linear;
  height: 100.1vh;
}

.rootHide {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: visibility 0s 1s, opacity 1s linear;
  height: 100.1vh;
}

.form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0;
}

.background {
  width: 100%;
  height: 100%;
  opacity: 1;
  user-select: none;
  transition-duration: .4s;
}

.backgroundHide {
  opacity: 0;
  transition-duration: .2s;
}

.loading {
  position: absolute;
  width: 4rem;
  height: 4rem;
  transform-style: preserve-3d;
  perspective: 800px;
  bottom: 120px;
}

@media (max-width: 1280px) {
  .loading {
    bottom: 100px;
  }
}

.loading .arc {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-bottom: 6px solid #D4FF00;
}

.loading .arc:nth-child(1) {
  animation: rotate1 1.15s linear infinite;
}

.loading .arc:nth-child(2) {
  animation: rotate2 1.15s linear infinite;
}

.loading .arc:nth-child(3) {
  animation: rotate3 1.15s linear infinite;
}

.loading .arc:nth-child(1) {
  animation-delay: -0.8s;
}

.loading .arc:nth-child(2) {
  animation-delay: -0.4s;
}

.loading .arc:nth-child(3) {
  animation-delay: 0s;
}

@keyframes rotate1 {
    from {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0);
    }
    to {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(1turn);
    }
}
@keyframes rotate2 {
    from {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0);
    }
    to {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(1turn);
    }
}
@keyframes rotate3 {
    from {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0);
    }
    to {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(1turn);
    }
}
