.root {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, .1);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 12px;
  transition: all .2s ease;
  width: 40px;
  height: 40px;
  border: none;
  cursor: pointer;
}

.root:hover {
  background: rgba(255, 255, 255, .4);
}

.root:active {
  background: #5156D1;
}

.active {
  background: #5156D1;
}
