.errors {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.text {
  display: flex;
  gap: 4px;
  align-items: center;
}

.text_icon {
  display: flex;
  align-items: center;
}

@media (max-width: 719px) {
  .text_icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
}

.textMessage {
  font-family: 'Space Grotesk', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  flex-grow: 1;
  text-align: left;
  color: #FFFFFF;
  opacity: 0.5;
  margin: 0;
}

.textMessageError {
  color: #FF764D;
}

.textMessageSuccess {
  color: #D4FF00;
}

.controls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  align-content: center;
  gap: 8px;
}

.controls input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}
