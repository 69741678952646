.input {
  width: 100%;
  border-radius: 24px;
  height: calc(48px - 2 * 12px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.1);
  outline: none;
  padding: 13px 16px 13px 16px;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 22px;
}

.input_error {
  border-color: #FF764D;
}
