.chatRoot {
  position: fixed;
  bottom: 80px;
  right: 25px;
  border-radius: 36px;
  background: rgba(25, 28, 62, 0.40);
  backdrop-filter: blur(8px);
  width: 360px;
  height: 552px;
  max-height: 552px;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflowY: 'auto';
  overflowX: 'hidden';
}

.chatHeader {
  display: flex;
  height: 56px;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;
}

.inputFiled {
  /*display: flex;*/
  /*gap: 8px;*/
  /*flex-direction: row;*/
  position: relative;
  padding: 8px;
  margin: 0;
  /*box-sizing: border-box;*/
  /*min-width: 100%;*/
}

.inputFiled input {
  border-radius: 32px;
  border: 1px solid rgba(255, 255, 255, 0.10);
  background: rgba(255, 255, 255, 0.04);
  /*max-width: 100%;*/
  padding-right: 52px;
  width: calc(100% - 52px - 16px);
}

.actionButtons {
  position: absolute;
  right: 14px;
  top: 14px;
  /*margin-right: -98px;*/
}

.chatBody {
  max-height: calc(100% - 72px);
  border-radius: 36px;
  background: rgba(25, 28, 62, 0.90);
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex-grow: 1;
  gap: 8px;
  justify-content: flex-end;
}

.avatarName {
  padding-left: 16px;
  width: 192px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.chatIcon {
  margin: 8px;
}

.speakerBlock {
  display: flex;
  padding: 8px;
  align-items: center;
  align-self: stretch;
  border-radius: 32px;
  border: 1px solid rgba(255, 255, 255, 0.20);
  background: #7A3AFF;
  margin: 8px;
}

.speakerText {
  color:#FFFFFF;
  font-size: 18px;
  font-style: normal;
  line-height: 24px; /* 133.333% */
  flex-grow: 1;
  margin-left: 16px;
}

@keyframes micOpacity {
  from { background: rgba(255, 255, 255, 0.08); }
  to { background: rgba(255, 255, 255, 0.24); }
}

.speakerMic {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.16);
  /*width: 40px;*/
  /*height: 40px;*/
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  animation: micOpacity 1s ease-in-out infinite alternate;
}

.speakerClose {
  margin-left: 8px;
}

.openButton {
  position: fixed;
  bottom: 78px;
  right: 16px;
}

@media (max-width: 650px) {
  .openButton {
    bottom: 24px;
    left: 54px;
  }

  .chatRoot {
    z-index: 20;
    bottom: 20px;
    right: calc(50% - 360px/2);
  }
}
