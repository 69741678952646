.root_mobile {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 1;
}

.root_desktop {
  position: absolute;
  left: 16px;
  bottom: 16px;
  display: none !important;
  z-index: 1;
}

.root_joystickVisible {
  position: absolute;
  bottom: 64px;
  left: 24px;
}

.joystick div {
  backdrop-filter: blur(4px) !important;
  background: rgba(23, 26, 48, 0.5) !important;
}

.joystick button {
  width: 54px !important;
  height: 54px !important;
  background: rgba(23, 26, 48, 0.7) !important;
}
