.root {
    position: absolute;
    bottom: 0;
    left: calc(50%  - 40px);
    width: 80px;
    height: 60px;
    z-index: 1000;
    padding: 8px;
    background-color: rgba(23, 26, 48, 0.9);
    border-radius: 24px;
    display: flex;
    justify-content: left;
    align-items: center;
}

.info {
    text-align: left;
    white-space: pre;
    color: white;
    font-size: 12px;
}
