.historyAction {
  font-style: italic;
  font-weight: 600;
}

.historyRoot {
  color: white;
  overflow: scroll;
  display: flex;
  padding: 16px 8px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
}

.message {
  border-radius: 4px 24px 24px 24px;
  border: 1px solid rgba(255, 255, 255, 0.10);
  background: rgba(255, 255, 255, 0.04);
  padding: 8px 16px;
}

.agent {
  margin-right: auto;
  max-width: 80%;
}

.player {
  margin-left: auto;
  max-width: 80%;
  border-radius: 24px 24px 4px 24px;
  background: #6B33DF;
}
